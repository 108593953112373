/**
 * Current domain
 */
export const domain = window.data.domainSlug;

/**
 *
 * @returns True if device is mobile
 */
export function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
  ) {
    return true;
  }
  return false;
}

/**
 *
 * @returns True if devices is iPad
 */
export function isIPad() {
  if (
    /iPad/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 && !window.MSStream)
  ) {
    return true;
  }
  return false;
}

/**
 *
 * @returns True if devices is phone
 */
export function isPhone() {
  return isMobile() && !isIPad();
}

/**
 *
 * @returns True if browser is Chrome
 */
export function isChrome() {
  return /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg/.test(navigator.userAgent);
}

/**
 *
 * @returns True if the app is in PWA mode
 */
export function isAppMode() {
  return !!(window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['iap-products-request']);
}
